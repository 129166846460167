import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import queryParams from '../../utils/queryParams';
import RichTextPreview from 'rev.sdk.js/Components/RichTextPreview';

function NewsPage(props) {
  const app = React.useContext(AppContext.Context);
  const {id} = queryParams(props.location.search);

  const [news, setNews] = React.useState(null);

  React.useEffect(() => {
    const _fetchData = async () => {
      AppActions.setLoading(true);
      try {
        let _news = await app.actions.getNewsById(id);
        setNews(_news);
      } catch (err) {
        //
      }
      AppActions.setLoading(false);
    };

    if (id) {
      _fetchData();
    }
  }, [id]);

  if (!news) {
    return null;
  }
  return (
    <Wrapper>
      <div className="container">
        <img className="banner" src={news.image || ''} />
        <RichTextPreview content={news?.content || []} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .container {
    margin: 0 auto;
    padding: var(--content-padding);
    max-width: var(--content-max-width);
    & > .banner {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

export default NewsPage;
